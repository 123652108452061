.mainToggleList {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 50px;
}

.mainToggleList_inner {
  display: flex;

  gap: 10px;
}


.leftBox {
  width: 200px;
  border: 1px solid #ddd;
  border-radius: 10px;

}

.rightBox {
  width: 200px;
  border: 1px solid #ddd;
  border-radius: 10px;
}

.mainToggleList_header {
  text-align: center;
  padding: 3px 0px 3px 0px;
  border-bottom: 1px solid #ddd;
  font-weight: 600;
}

.mainToggleList_body {

  overflow: auto;
  height: 200px;
}

.arrows {
  display: flex;
  justify-content: space-between;
  flex-direction: column;
  height: 100%;
}

.center_arrows {
  display: flex;
  flex-direction: column;
  gap: 15px;
}

.selected_sites_single {
  display: flex;
  cursor: pointer;
  padding: 2px;
  margin-top: 1px;
  padding-left: 10px;
}

.selected_hq {
  background-color: #e6e6e6;
}

.selected_hq_text {
  margin: 0;
  padding: 5px 0px;
}
.hq-page {
  .hq-imanage-files {
    margin: 0;
    display: flex;
    justify-content: center;
    border-bottom: 1px solid #ddd;
  }

  .hq-tree {
    flex: 1;
    padding: 10px;
    margin: 0 1em;
  }

  .button-right {
    float: right;
    margin-right: 20px;
    margin-left: 0;
  }

  .button-centre {
    border-radius: 4px;
    height: 36px;
    margin-top: 30px;
    padding: 10px 20px;
    position: relative;
    border-color: rgb(234 234 234);
    top: 3px;
  }

  padding: 0;
}
@import "../../cui/sass/base";

.hq-page {
  padding: 20px 0 0 0;
  background-color: $white;

  &,
  .hq_modal {
    background-color: transparent;
  }

  &,
  .hq_button,
  .dx-widget,
  .dx-theme-generic-typography,
  .dx-widget input,
  .dx-widget textarea,
  .MuiFormControlLabel-root>span {
    font-family: "Roboto", sans-serif, Arial;
  }

  .rollover-msg>p {
    font-family: "Roboto", sans-serif, Arial;
  }

  .rollover-msg-body {
    padding-left: 20px;
    padding-bottom: 10px;
  }

  .rollover-msg-body>p {
    font-family: "Roboto", sans-serif, Arial;
  }

  .hq-icon-button {
    border: none;
    color: $blue--400;
  }

  .hq-icon-button>.dx-button-content {
    padding: 0;
  }

  .hq-icon-button>.dx-button-content>.dx-icon {
    color: $blue--400;
  }

  &__rollover-header {
    border-bottom: 1px solid $blue-100 !important;
    color: $grey--600;
    padding-bottom: 1rem;
    margin-bottom: 1rem;
  }

  .hq_button {
    background-color: transparent;
    border-width: 2px;
    border-style: solid;
    border-radius: 3px;
    border-color: $blue-100;
    font-weight: 550;
    text-transform: capitalize;
    transition: all 0.5s;
    line-height: normal;
    margin-left: 1rem;
    color: $blue-100;

    &:hover {
      text-decoration: none;
      color: $white;
      background-color: $blue-100;
      border-color: $blue-100;
    }
  }

  button:disabled {
    color: $grey--100;
    border-color: $grey--100;
    background-color: transparent;
  }
}

body.dx-theme-generic-typography #root {
  background-color: $white;
}

.rollover-container {
  display: flex;
}
.tax-container {
  padding: 10px;
  width: 40%;
}
.tax-container .col {
  display: flex;
  justify-content: space-between;
}
h2.hq-title {
  font-family: "Roboto", sans-serif, Arial;
  color: #343434;
  font-size: 1rem;
  font-weight: bold;
  line-height: 24px;
  padding-bottom: 20px;
}
.rollover_options {
  display: flex;
  height: 100%;
  width: 100%;
  padding: 10px;
}
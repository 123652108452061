.grid-container {
  margin: 16px
}

.grid-header {
  display: flex;
  margin: 10px 0 10px 0;
}
.dx-datagrid .dx-row.dx-row-hover {
    background-color: #f5f5f5 !important;
    cursor: pointer;
    transition: background-color 0.3s ease;
  }